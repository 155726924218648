import {Motion} from "@owowagency/gsap-motion";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {debounce} from "lodash";
import {fromEvent} from "rxjs";
import {Vector2} from "three";

import {isClient} from "@/utils/isClient";
import {nextAnimationFrame} from "@/utils/nextAnimationFrame";

type ViewportMotionMeta = {
  onHorizontalChange: Set<(viewport: Vector2) => void>,
  onVerticalChange: Set<(viewport: Vector2) => void>,
  onOrientationChange: Set<(viewport: Vector2) => void>,
  onChange: Set<(viewport: Vector2) => void>,
  viewport: Vector2;
  reset: VoidFunction;
  orientation: "landscape" | "portrait";
};

export const viewportHandler = isClient()
    ? new Motion<ViewportMotionMeta>(({reset, meta, subscriptions}) => {
        // init
        meta.reset = debounce(reset, 100);
        meta.onHorizontalChange ??= new Set();
        meta.onVerticalChange ??= new Set();
        meta.onOrientationChange ??= new Set();
        meta.onChange ??= new Set();
        meta.viewport ??= new Vector2(innerWidth, innerHeight);
        meta.orientation ??= innerWidth >= innerHeight ? "landscape" : "portrait";

        // subscribe to viewport events
        if (window.screen.orientation) {
            subscriptions.push(fromEvent(window.screen.orientation, "change").subscribe(meta.reset));
        }

        subscriptions.push(
            fromEvent(window, 'resize').subscribe(meta.reset),
            fromEvent(window, 'orientationchange').subscribe(meta.reset)
        );

        // update values
        const newHeight = innerHeight;
        const isHeightChanged = newHeight !== meta.viewport.height;
        const newWidth = innerWidth;
        const isWidthChanged = newWidth !== meta.viewport.width;
        const newOrientation = innerWidth >= innerHeight ? "landscape" : "portrait";
        const isOrientationChanged = newOrientation !== meta.orientation;

        meta.viewport.set(innerWidth, innerHeight);
        meta.orientation = newOrientation;

        const handleChange = async (fn: (viewport: Vector2) => void) => {
            await nextAnimationFrame(() => {
                fn(meta.viewport);
            }, 2000);
        };

        if (isHeightChanged || isWidthChanged || isOrientationChanged) {
            meta.onChange.forEach(handleChange);
        }

        if (isHeightChanged && !isWidthChanged) {
            meta.onVerticalChange.forEach(handleChange);
        }

        if (!isHeightChanged && isWidthChanged) {
            meta.onHorizontalChange.forEach(handleChange);
        }

        if (isOrientationChanged) {
            if (ScrollTrigger.isTouch === 1) {
                if (ScrollSmoother.get()) {
                    ScrollSmoother.get().scrollTop(0);
                } else {
                    window.scrollTo({top: 0});
                }
            }

            meta.onOrientationChange.forEach(handleChange);
        }
    })
    : null;
