import React, {createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {LoadingManager, Texture, TextureLoader} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

import {Stage} from "./Stage";

export type ProgressHandler = (url: string, loaded: number, total: number) => void;
export type LoadHandler = () => void;

export type UseLoaderManagerParams = {
  onStart?: ProgressHandler;
  onProgress?: ProgressHandler;
  onLoad?: LoadHandler;
}

export type LoadingManagerState = {
  loadedAmount: number;
  totalAmount: number;
  progress: number;
}

export const loadingManager = new LoadingManager();
export const textureLoader = new TextureLoader(loadingManager);
export const modelLoader = new GLTFLoader(loadingManager);
export const loadingStartHandlers = new Set<ProgressHandler | null | undefined>();
export const loadingProgressHandlers = new Set<ProgressHandler | null | undefined>();
export const loadingLoadHandlers = new Set<LoadHandler | null | undefined>();

const textureCache = new Map<string, Texture>();

loadingManager.onError = (url) => {
    // eslint-disable-next-line no-console
    console.error(`Could not load ${url}`);
};

loadingManager.onStart = (...params) => {
    loadingStartHandlers.forEach(handler => handler?.(...params));
};

loadingManager.onProgress = (...params) => {
    loadingProgressHandlers.forEach(handler => handler?.(...params));
};

loadingManager.onLoad = (...params) => {
    loadingLoadHandlers.forEach(handler => handler?.(...params));
    requestAnimationFrame(() => {
        Stage.instance.renderer.compile(Stage.instance.scene, Stage.instance.camera);
    });
};

export function loadTexture(
    url: string,
    onLoad?: (texture: Texture) => void,
    onProgress?: (event: ProgressEvent) => void,
    onError?: (event: ErrorEvent) => void,
    key?: string,
): Texture {
    const realKey = typeof key === 'string' ? `${url}-${key}` : url;

    if (textureCache.has(realKey)) {
        const texture = textureCache.get(realKey);
        onLoad?.(texture);
        return texture;
    }

    textureCache.set(realKey, textureLoader.load(url, (texture) => {
        onLoad?.(texture);
    }, onProgress, onError));

    return textureCache.get(realKey);
}

export function useLoadingManager(params?: UseLoaderManagerParams) {
    const handlers = useRef(params ?? {}).current;
    const state = useContext(LoadingManagerContext);

    useEffect(() => {
        loadingStartHandlers.add(handlers.onStart);
        loadingProgressHandlers.add(handlers.onProgress);
        loadingLoadHandlers.add(handlers.onLoad);

        return () => {
            loadingStartHandlers.delete(handlers.onStart);
            loadingProgressHandlers.delete(handlers.onProgress);
            loadingLoadHandlers.delete(handlers.onLoad);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return state;
}

const defaultState: LoadingManagerState = {
    loadedAmount: 0,
    totalAmount: 0,
    progress: 0,
};

export const LoadingManagerContext = createContext(defaultState);

export const LoadingManagerProvider = (props: PropsWithChildren) => {
    const [state, setState] = useState<LoadingManagerState>(defaultState);

    const progress = state.loadedAmount === 0 && state.totalAmount === 0
        ? 0
        : state.loadedAmount / state.totalAmount;

    useEffect(() => {
        const handleStart: ProgressHandler = (_, itemsLoaded, itemsTotal) => {
            setState(state => ({
                ...state,
                totalAmount: itemsTotal,
                loadedAmount: itemsLoaded,
            }));
        };

        const handleProgress: ProgressHandler = (_, itemsLoaded, itemsTotal) => {
            setState(state => ({
                ...state,
                totalAmount: itemsTotal,
                loadedAmount: itemsLoaded,
            }));
        };

        const handleLoad: LoadHandler = () => {
            setState(state => ({
                ...state,
            }));
        };

        loadingStartHandlers.add(handleStart);
        loadingProgressHandlers.add(handleProgress);
        loadingLoadHandlers.add(handleLoad);

        return () => {
            loadingStartHandlers.delete(handleStart);
            loadingProgressHandlers.delete(handleProgress);
            loadingLoadHandlers.delete(handleLoad);
        };
    }, []);

    return <LoadingManagerContext.Provider value={{...state, progress}}>
        {props.children}
    </LoadingManagerContext.Provider>;
};
