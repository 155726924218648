import React, {Dispatch, PropsWithChildren, SetStateAction, useState} from 'react';

import {Color} from "@/components/HorizontalColorPicker";

type Type = [Color, Dispatch<SetStateAction<Color>>];

const ColorContext = React.createContext<Type>([
    'salmon',
    () => {
        /* */
    },
]);

export const BG_COLORS = {
    'salmon': '#c29a94',
    'olive': '#abc96e',
    'lilac': '#b1b0f3',
    'dust': '#f5d980',
};

export const useColor = () => {
    return React.useContext(ColorContext);
};

interface Props extends PropsWithChildren {
    color: Color,
}

export const ColorProvider = ({color, children}: Props) => {
    const state = useState(color);
    return (
        <ColorContext.Provider value={state}>
            {children}
        </ColorContext.Provider>
    );
};
