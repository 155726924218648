import {TextClipReveal} from '@owowagency/gsap-motion';
import {useLocation} from '@reach/router';
import {gsap} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import React, {PropsWithChildren, ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';

import {colorMap} from '@/components/HorizontalColorPicker';
import {ColorProvider, useColor} from '@/context/ColorContext';
import {SharedImageDataLoaderProvider} from "@/context/SharedImageDataLoaderContext";
import useWindow from '@/hooks/useWindow';

const Wrapper = ({children}: PropsWithChildren) => {
    return (
        <ColorProvider color={'salmon'}>
            <Layout>
                {children}
            </Layout>
        </ColorProvider>
    );
};



const Layout = ({children}: { children: ReactNode }) => {
    const [currentColor] = useColor();

    const pathId = 'pageTranstionPath';

    const location = useLocation();
    const textClipRevealRef = useRef<TextClipReveal[]>([]);

    const [pathname, setPathname] = useState(location.pathname);

    const [animating, setAnimating] = useState(false);

    const {width, height} = useWindow();

    const childrenCurrent = useRef<ReactNode>();

    const initial = useMemo(() => `M0 ${height} L${width} ${height} L${width} ${height} Q${width / 2} ${height} 0 ${height} Z`, [width, height]);

    if (location.pathname !== '/' && typeof window !== 'undefined') {
        sessionStorage.setItem("first_time", "no");
    }

    useEffect(() => {
        if (width && height) {
            animate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const colors = useMemo(() => location.pathname == "/" ? ['#efefef', colorMap[currentColor]] : ['#efefef', '#fff'], [currentColor, location.pathname]);

    const animate = async () => {
        setAnimating(true);

        const t = gsap.timeline();

        for (const [index, color] of colors.entries()) {
            const id = `#${pathId}${index ? 'Second' : 'First'}`;

            await t.to(id, {duration: 0, ease: 'none', attr: {d: initial}, fill: color, opacity: 1});

            await t.to(id, {duration: .45, ease: 'none', attr: {d: `M0 ${height} L${width} ${height} L${width} ${height / 2} Q${width / 2} ${height * 0.25} 0 ${height / 2} Z`}});

            await t.to(id, {duration: .45, ease: 'ease.power4', attr: {d: `M0 ${height} L${width} ${height} L${width} 0 Q${width / 2} 0 0 0 Z`}});

            if (index === 0) {
                childrenCurrent.current = children;

                setPathname(location.pathname);
            }
        }

        await t.to(`#${pathId}First`, {duration: 0, opacity: 0});

        await t.to(`#${pathId}Second`, {duration: .8, opacity: 0});

        setAnimating(false);
    };

    if (childrenCurrent.current === undefined) {
        childrenCurrent.current = children;
    }

    useLayoutEffect(() => {
        const instances = textClipRevealRef.current;
        const titlesQuery = '.text-clip-reveal';
        const elements = gsap.utils.toArray<HTMLElement>(titlesQuery);

        TextClipReveal.SplitText ??= SplitText;

        for (const element of elements) {
            instances.push(
                new TextClipReveal(element, {
                    fromVars: {
                        lineHeight: 1.26,
                    },
                    toVars: {
                        stagger: {each: 0.07},
                        ease: 'power1.out',
                        scrollTrigger: {
                            trigger: element,
                            start: 'top bottom',
                            end: 'top 25%',
                            scrub: 0.5,
                            once: true,
                        },
                    },
                }, {
                    shouldResetOnResize: document.body,
                })
            );
        }

        return () => {
            while (instances.length) {
                instances.pop()?.destroy();
            }
        };
    }, []);

    return (
        <SharedImageDataLoaderProvider>

            <div key={pathname}>
                {childrenCurrent.current}
            </div>

            {width && height && <svg className='position-fixed bottom-0 left-0 right-0 top-0'
                style={{zIndex: 999, pointerEvents: animating ? 'auto' : 'none'}}
                viewBox={`0 0 ${width} ${height}`}
            >
                <path
                    d={initial}
                    id={`${pathId}First`}
                />

                <path
                    d={initial}
                    id={`${pathId}Second`}
                />
            </svg>}

        </SharedImageDataLoaderProvider>
    );
};

export default Wrapper;
