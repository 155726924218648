import React, {useCallback, useMemo, useState} from 'react';

import {Frame} from "@/utils/getFrames";
import {SharedImageDataLoader} from "@/utils/ImageDataLoader";

interface SharedLoaderContext {
    percentage: number,
    register: (frames: Frame[], type: LoadType) => void,
    prepareFirst: (frame: Frame) => Promise<HTMLImageElement>,
    prepare: (frames: Frame[], type: LoadType) => Promise<HTMLImageElement[]>,
    onEntered: boolean,
    showLoader: boolean,
    setEntered: () => void,
    onEnteredBefore: boolean,
}

type LoadType = 'immediate' | 'lazy';

type FrameState = {
    type: LoadType,
    value: boolean,
}

export const SharedImageDataLoaderContext = React.createContext<SharedLoaderContext>(undefined);

export const SharedImageDataLoaderProvider = ({children}: { children: React.ReactNode }) => {
    const [loader] = useState(() => new SharedImageDataLoader());
    const [sources, setSources] = useState<Record<string, FrameState>>({});
    const allFramesLoadedState = Object.values(sources);
    const allRequiredFramesCount = allFramesLoadedState.filter(a => a.type == 'immediate').length;
    const loadedRequiredFramesCount = allFramesLoadedState.filter(a => a.type == 'immediate' && a.value).length;
    const progress = allRequiredFramesCount > 0 ? loadedRequiredFramesCount / allRequiredFramesCount : 0;
    const percentage = useMemo(() => Math.floor(progress * 100), [progress]);
    const [onEnteredBefore, setOnEnteredBefore] = useState(false);
    const [onEntered, setOnEntered] = useState(false);

    const setLoaded = useCallback((frame: Frame, type: LoadType) => {
        setSources((prev) => {
            const current = prev[frame.webp];
            if (!current?.value) {
                return {...prev, [frame.webp]: {type, value: true}};
            }
            return prev;
        });
    }, [setSources]);

    const loadImmediate = useCallback(async (frame: Frame, type: LoadType) => {
        const data = await loader.get(frame);
        setLoaded(frame, type);
        return data;
    }, [loader, setLoaded]);

    const register = useCallback((frames: Frame[], type: LoadType) => {
        setSources((prev) => {
            const copy = {...prev};
            for (const frame of frames) {
                if (!copy[frame.webp]) {
                    copy[frame.webp] = {type, value: false};
                }
            }
            return copy;
        });
    }, [setSources]);

    const prepareFirst = useCallback(async (frame: Frame) => {
        return loadImmediate(frame, 'immediate');
    }, [loadImmediate]);

    const prepare = useCallback(async (frames: Frame[], type: LoadType) => {
        return Promise.all(frames.map((frame) => loadImmediate(frame, type)));
    }, [loadImmediate]);

    const showLoader = useMemo(() => percentage < 100 || percentage === 100 && !onEnteredBefore, [
        onEnteredBefore, percentage,
    ]);


    const state: SharedLoaderContext = useMemo(() => ({
        percentage,
        register,
        prepareFirst,
        prepare,
        onEntered,
        showLoader,
        onEnteredBefore,
        setEntered: () => {
            setOnEnteredBefore(true);
            setOnEntered(true);
        },
    }), [percentage, register, prepareFirst, prepare, onEntered, showLoader, onEnteredBefore]);

    return (<SharedImageDataLoaderContext.Provider value={state}>
        {children}
    </SharedImageDataLoaderContext.Provider>);
};

export const useImageLoader = () => React.useContext(SharedImageDataLoaderContext);
