import '@/assets/styles/index.scss';

import React from 'react';

import {LoadingManagerProvider} from '@/objects/LoadingManager';

export const wrapRootElement = ({element}) => (<LoadingManagerProvider>
    {element}
</LoadingManagerProvider>);

export const shouldUpdateScroll = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    return false;
};
