import {Texture} from "three";

import {loadTexture} from "./LoadingManager";

type TextureType = ('normal' | 'roughness' | 'diffuse' | 'envMap');
type TextureSources = Record<TextureType, string>;
type TextureMap = Record<TextureType, Texture>;

export default class ObjectTextureLoader extends EventTarget implements TextureMap {
    loaded: TextureType[] = [];
    normal: Texture;
    roughness: Texture;
    diffuse: Texture;
    envMap: Texture;

    get isLoaded(): boolean {
        for (const type of ObjectTextureLoader.ALL) {
            if (this.loaded.indexOf(type) === -1) {
                return false;
            }
        }

        return true;
    }

    constructor(sources: TextureSources, key: string) {
        super();

        for (const type of ObjectTextureLoader.ALL) {
            this[type] = loadTexture(sources[type], () => this.onLoad(type), undefined, undefined, key);
        }
    }

    onLoad(type: TextureType) {
        this.loaded.push(type);
        if (this.isLoaded) {
            this.dispatchEvent(new Event('loaded'));
        }
    }

    static ALL: TextureType[] = ['normal', 'roughness', 'diffuse', 'envMap'];
}
