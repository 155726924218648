exports.components = {
  "component---src-components-page-tsx": () => import("./../../../src/components/Page.tsx" /* webpackChunkName: "component---src-components-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-general-terms-tsx": () => import("./../../../src/pages/general-terms.tsx" /* webpackChunkName: "component---src-pages-general-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partnerships-dmix-tsx": () => import("./../../../src/pages/partnerships/dmix.tsx" /* webpackChunkName: "component---src-pages-partnerships-dmix-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-partnerships-vizoo-tsx": () => import("./../../../src/pages/partnerships/vizoo.tsx" /* webpackChunkName: "component---src-pages-partnerships-vizoo-tsx" */),
  "component---src-pages-partnerships-x-rite-tsx": () => import("./../../../src/pages/partnerships/x-rite.tsx" /* webpackChunkName: "component---src-pages-partnerships-x-rite-tsx" */),
  "component---src-pages-platforms-tsx": () => import("./../../../src/pages/platforms.tsx" /* webpackChunkName: "component---src-pages-platforms-tsx" */),
  "component---src-pages-use-cases-furniture-brands-tsx": () => import("./../../../src/pages/use-cases/furniture-brands.tsx" /* webpackChunkName: "component---src-pages-use-cases-furniture-brands-tsx" */),
  "component---src-pages-use-cases-index-tsx": () => import("./../../../src/pages/use-cases/index.tsx" /* webpackChunkName: "component---src-pages-use-cases-index-tsx" */),
  "component---src-pages-vlogs-index-tsx": () => import("./../../../src/pages/vlogs/index.tsx" /* webpackChunkName: "component---src-pages-vlogs-index-tsx" */)
}

