import {Motion, Pointer} from "@owowagency/gsap-motion";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {AmbientLight, ColorManagement, DirectionalLight, DirectionalLightHelper, Mesh, OrthographicCamera, PointLight, PointLightHelper, Scene, SphereGeometry, Vector2, WebGLRenderer} from "three";

import {configureLilGUI, destroyGUI} from "@/utils/gui";
import {isClient} from "@/utils/isClient";
import {nextAnimationFrame} from "@/utils/nextAnimationFrame";

import {Chair} from "./Chair";
import {viewportHandler} from "./viewportHandler";

const {interpolate} = gsap.utils;
const POINTER_STEP_PROGRESS = 0.1;
const RADIUS = 100;
const DPR_LIMIT_THRESHOLD = 2_000_000;
const DPR_LIMIT_MIN = 1;
const DPR_LIMIT_MAX = 2;

/**
 * Singleton Stage object, contains our scene and related members and objects.
 */
export class Stage {
    static objectsZ = -500;
    private static __instance: Stage;
    private static destroying = false;

    static create() {
        return this.instance;
    }

    static async destroy() {
        const instance = this.__instance;

        if (!instance || this.destroying) {
            return;
        }

        this.destroying = true;

        // Destroy helper GUI
        destroyGUI();

        // Wait for unrelated stage cleanup calls (e.g. react clean-up and other side effects)
        await nextAnimationFrame();

        // Stop the ticker
        gsap.ticker.remove(instance.update);

        // Remove <canvas> element
        instance.renderer.domElement.remove();

        // Clear the scene and dispose of geometries and materials
        while (instance.scene.children.length) {
            const child = instance.scene.children[0];

            if (child instanceof Mesh) {
                child.geometry.dispose();
                child.material.dispose();
            }

            instance.scene.remove(child);
        }

        // Destroy instance members
        for (const [key, member] of Object.entries(instance)) {
            if (member instanceof Motion) {
                member.destroy();
                delete this[key];
                continue;
            }

            delete this[key];
        }

        // Clear the instance
        this.__instance = undefined;
        this.destroying = false;
    }

    static get instance() {
        return this.__instance ??= isClient() ? new this() : null;
    }

    scene = new Scene();
    camera = new OrthographicCamera();
    geometry = new SphereGeometry(RADIUS, 70, 35);
    update: gsap.Callback;

    renderer = (() => {
        const renderer = new WebGLRenderer({
            alpha: true,
            antialias: devicePixelRatio < DPR_LIMIT_MAX,
        });

        return renderer;
    })();

    pointLight = new PointLight(0xfffffff, 0.6);
    pointLightHelper = (() => {
        const helper = process.env.NODE_ENV !== 'production'
            ? new PointLightHelper(this.pointLight, 100)
            : null;

        if (helper !== null) {
            helper.position.z = Stage.objectsZ;
            this.scene.add(helper);
        }

        configureLilGUI((gui) => {
            const folder = gui.addFolder('point light');
            folder.add(this.pointLight, 'intensity', 0, 1);
            folder.add(this.pointLight.position, 'z', -1000, 1000);
            folder.addColor(this.pointLight, 'color');
        });

        return helper;
    })();

    ambientLight = (() => {
        const light = new AmbientLight(0xffffff, .16);

        configureLilGUI((gui) => {
            const folder = gui.addFolder('ambient light');
            folder.add(light, 'intensity', 0, 1);
            folder.addColor(light, 'color');
        });

        return light;
    })();

    directionalLight = (() => {
        const light = new DirectionalLight(0xffffff, 1);
        light.position.y = 1;
        light.position.x = -1;
        light.position.z = 0.94;
        return light;
    })();

    directionalLightHelper = (() => {
        const helper = process.env.NODE_ENV !== 'production'
            ? new DirectionalLightHelper(this.directionalLight)
            : null;

        configureLilGUI((gui) => {
            const folder = gui.addFolder('directional light');
            folder.add(this.directionalLight.position, 'x', -5, 5);
            folder.add(this.directionalLight.position, 'y', -5, 5);
            folder.add(this.directionalLight.position, 'z', -5, 5);
            folder.add(this.directionalLight, 'intensity', 0, 1);
            folder.addColor(this.directionalLight, 'color');
        });

        return helper;
    })();

    // Set up renderer and camera to handle screen resizes on various devices.
    viewportController = new Motion<{
      currentViewport?: Vector2;
      initialViewport?: Vector2;
      isMobile?: MediaQueryList;
    }>(({meta}) => {
        meta.initialViewport ??= new Vector2(innerWidth, innerHeight);
        meta.currentViewport = new Vector2(innerWidth, innerHeight);
        this.setupRenderer(meta.currentViewport);
        this.setupCamera(meta.currentViewport);

        viewportHandler?.meta.onChange.add(() => {
            meta.currentViewport = new Vector2(innerWidth, innerHeight);
            this.setupRenderer(meta.currentViewport);
            this.setupCamera(meta.currentViewport);
        });
    });

    // Synchronize camera movement with scroll gestures
    cameraController = new Motion<{
        scrollTrigger: ScrollTrigger;
        updater: gsap.TickerCallback;
    }>(({meta}) => {
        meta.updater = gsap.ticker.add(() => {
            this.camera.position.y = -(ScrollSmoother.get()?.scrollTop() ?? 0);
        });

        return () => {
            gsap.ticker.remove(meta.updater);
        };
    });

    // Make the point light follow the pointer
    pointLightController = new Motion(() => {
        const viewport = new Vector2(innerWidth, innerHeight);

        const updater = gsap.ticker.add(() => {
            this.pointLight.position.x = interpolate(
                this.pointLight.position.x,
                Pointer.instance.clientX - viewport.width / 2,
                POINTER_STEP_PROGRESS * gsap.ticker.deltaRatio()
            );
            this.pointLight.position.y = interpolate(
                this.pointLight.position.y,
                -Pointer.instance.clientY + viewport.height / 2 + this.camera.position.y,
                POINTER_STEP_PROGRESS * gsap.ticker.deltaRatio()
            );
        });

        return () => {
            gsap.ticker.remove(updater);
        };
    }, {shouldResetOnResize: window, watchMedia: '(pointer: fine)'});

    private constructor() {
        ColorManagement.enabled = true;
        this.pointLight.position.z = 550;
        this.camera.position.z = 1;
        this.scene.add(this.pointLight, this.ambientLight, this.directionalLight, this.camera);
        new Chair(m => this.scene.add(m));
        this.update = gsap.ticker.add(() => this.renderer.render(this.scene, this.camera), false, true);

        document.querySelector('main').prepend(this.renderer.domElement);

        gsap.set(this.renderer.domElement, {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100svh',
            zIndex: 7,
            pointerEvents: 'none',
        });

        ScrollTrigger.create({
            trigger: 'main',
            start: 'top top',
            end: 'bottom top',
            pin: this.renderer.domElement,
            pinSpacing: false,
        });
    }

    setupRenderer(dimensions: Vector2) {
        const limit = dimensions.width * dimensions.height >= DPR_LIMIT_THRESHOLD
            ? DPR_LIMIT_MIN
            : DPR_LIMIT_MAX;
        this.renderer.setSize(dimensions.width, dimensions.height);
        this.renderer.setPixelRatio(
            Math.min(limit, devicePixelRatio)
        );
    }

    setupCamera(dimensions: Vector2) {
        this.camera.near = .1;
        this.camera.left = dimensions.width * -0.5;
        this.camera.right = dimensions.width * 0.5;
        this.camera.top = dimensions.height * 0.5;
        this.camera.bottom = dimensions.height * -0.5;
        this.camera.updateProjectionMatrix();
        this.camera.position.z = 1;
    }
}
