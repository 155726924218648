import classnames from 'classnames';
import React, {CSSProperties, useEffect, useRef, useState} from 'react';

import * as styles from '@/assets/styles/elements/HorizontalColorPicker.module.scss';

export const colors = ['salmon', 'lilac', 'olive', 'dust'] as const;

export const colorMap: Record<typeof colors[number], string> = {
    salmon: '#dfb0a9',
    lilac: '#c8c7ff',
    olive: '#c6e38b',
    dust: '#fde391',
};

export const ribbonColorMap: Record<typeof colors[number], string> = {
    salmon: '#dfb0a9',
    lilac: '#b6b4ff',
    olive: '#b3e2b6',
    dust: '#ffefb8',
};

export const colorBlendMap: Record<typeof colors[number], number> = {
    salmon: 0,
    lilac: 1,
    olive: 1,
    dust: 1,
};

export const colorLightnessMap: Record<typeof colors[number], number> = {
    salmon: 1,
    lilac: 1,
    olive: 1,
    dust: 1,
};

export type Color = typeof colors[number];

interface Props {
    onChange: (color: Color) => void;
    color: Color;
    loading: boolean;
    entered: boolean;
}

const didClickElement = (element: Element, event: MouseEvent) => {
    return element == event.target || element.contains(event.target as Node);
};

const HorizontalColorPicker: React.FC<Props> = ({onChange: onChanged, color: selectedColor, loading, entered}: Props) => {
    const root = useRef();
    const didExpandOnce = useRef(false);
    const [collapsed, setCollapsed] = useState(true);
    const [canCollapse, setCanCollapse] = useState(true);
    const computedCollapsed = canCollapse && collapsed;
    const swatchColors = ['#dfb0a9', '#c8c7ff', '#c6e38b', '#fde391'];

    const onChangeColor = (c: Color) => {
        if (computedCollapsed) {
            setCollapsed(false);
        } else {
            setCollapsed(true);
            onChanged(c);
        }
    };

    // First time `loaded` is set to true, set can collapse to false after a 1s delay
    useEffect(() => {
        if (loading) {
            return;
        }

        if (!entered) {
            return;
        }

        if (didExpandOnce.current) {
            return;
        }

        const handle = setTimeout(() => {
            didExpandOnce.current = true;
            setCanCollapse(false);
        }, 1000);

        return () => clearTimeout(handle);
    }, [loading, entered, setCollapsed]);

    // when `canCollapse` is set to false, automatically set can collapse to true after a 1.5s delay
    useEffect(() => {
        if (canCollapse) {
            return;
        }

        const handle = setTimeout(() => {
            setCanCollapse(true);
        }, 1500);

        return () => clearTimeout(handle);
    }, [canCollapse, setCanCollapse]);

    useEffect(() => {
        const element = root.current;
        if (!element) {
            return;
        }

        const listener = (event: MouseEvent) => {
            if (!didClickElement(element, event)) {
                setCollapsed(true);
            }
        };

        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('click', listener);
        };
    }, [root, setCollapsed]);

    return <div className={classnames(
        styles.colorPickerWrapper,
        loading ? styles.loading : '',
        styles.loadedOnce
    )}
    ref={root}>
        <div
            className={classnames(styles.colorPicker, 'position-relative d-flex bottom-0 left-0 mb-6 mb-xl-12 ml-6 ml-xl-15 rounded-pill', computedCollapsed ? styles.collapsed : '')}
            id='color-picker'
        >
            {colors.map((color, index) => <div
                className={classnames(styles.colorOptionWrapper, 'd-flex align-items-center justify-content-center', selectedColor === color ? styles.selected : '')}
                key={color}
                style={{'--translate-x': `-${index * 100}%`} as CSSProperties}>
                <div
                    className={classnames(
                        styles.colorOption,
                        'rounded-pill cursor-pointer',
                    )}
                    onClick={() => onChangeColor(color)}
                    style={{backgroundColor: swatchColors[index]} as CSSProperties}
                />
            </div>
            )}
        </div>
    </div>;
};

export default HorizontalColorPicker;

