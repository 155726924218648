import GUI from 'lil-gui';

let instance: GUI | undefined;

async function getGUI(): Promise<GUI> {
    return new Promise((resolve) => {
        instance ??= new GUI().close();
        resolve(instance);
    });
}

export function destroyGUI() {
    instance?.destroy();
    instance = undefined;
}

export function configureLilGUI(callback: (gui: GUI) => void) {
    if (process.env.NODE_ENV !== 'production') {
        getGUI().then(callback);
    }
}
