import {Motion, Pointer, SecondOrderDynamics} from '@owowagency/gsap-motion';
import {gsap} from 'gsap';
import {ScrollSmoother} from 'gsap/ScrollSmoother';
import {BufferGeometry, Color, IUniform, Mesh, MeshStandardMaterial, Object3D, RepeatWrapping, Texture, Vector2} from 'three';

import {configureLilGUI} from '@/utils/gui';

import ObjectTextureLoader from './3DTextureLoader';
import {modelLoader} from './LoadingManager';
import {Stage} from './Stage';
import {createTextileMaterial} from './TextileMaterial';
import {TEXTURE_SOURCES} from './textures';
import {viewportHandler} from './viewportHandler';

const TEXTURE_REPEAT = 15;
const OBJECT_LOOKAT_ANGLES = new Vector2(Math.PI * 0.2, Math.PI * 0.1);
const OBJECT_SCROLL_ROTATION_RANGE = Math.PI * 2;
const OBJECT_SCROLL_ROTATION_BASE = -Math.PI * 0.15;
const OBJECT_SCROLL_ROTATION_START = -(OBJECT_SCROLL_ROTATION_RANGE / 2) + OBJECT_SCROLL_ROTATION_BASE;
const OBJECT_SCROLL_ROTATION_END = OBJECT_SCROLL_ROTATION_RANGE / 2 + OBJECT_SCROLL_ROTATION_BASE;
const OBJECT_ROTATION_X = Math.PI * -0.5;

const {mapRange} = gsap.utils;

let textures: {
    normal?: Texture;
    roughness?: Texture;
    diffuse?: Texture;
    envMap?: Texture;
};

type ChairPositionMeta = {
    sentinel?: HTMLElement | null;
    rect?: DOMRect;
    setMeshPosition: () => void;
}

export class Chair {
    static instance: Chair;
    static scale = 3.2;
    static _textures: ObjectTextureLoader;

    static get textures(): ObjectTextureLoader {
        if (Chair._textures) {
            return Chair._textures;
        }

        Chair._textures = new ObjectTextureLoader(TEXTURE_SOURCES, 'chair');

        for (const type of ObjectTextureLoader.ALL) {
            const texture = Chair._textures[type];
            texture.wrapS = texture.wrapT = RepeatWrapping;
            texture.repeat.x = texture.repeat.y = TEXTURE_REPEAT;
        }

        configureLilGUI((gui) => {
            const values = {repeat: TEXTURE_REPEAT};
            gui.addFolder('chair texture')
                .add(values, 'repeat', 0, 100)
                .name('scale')
                .onChange((value: number) => {
                    for (const [, texture] of Object.entries(textures)) {
                        texture.repeat.x = texture.repeat.y = value;
                    }
                });
        });

        return Chair._textures;
    }

    sharedUniforms: Record<string, IUniform>;
    mesh?: Mesh<BufferGeometry, MeshStandardMaterial>;

    positionController = new Motion<ChairPositionMeta>(({meta}, context) => {
        meta.sentinel ??= document.getElementById("furniture-canvas");

        meta.setMeshPosition = () => {
            const scrollY = ScrollSmoother.get()?.scrollTop() ?? 0;
            const calculateX = (rectX: number) => rectX - window.innerWidth / 2;
            const calculateY = (rectY: number) => -rectY + window.innerHeight / 2 - scrollY;

            meta.rect = meta.sentinel?.getBoundingClientRect();

            if (this.mesh) {
                const chairSize = Math.abs(
                    this.mesh.geometry.boundingBox.min.x - this.mesh.geometry.boundingBox.max.x
                );
                const sentinelSize = document.getElementById("furniture-canvas")?.getBoundingClientRect()?.width ?? 350;
                const scale = sentinelSize / chairSize * 0.55;
                this.mesh.scale.set(scale, scale, scale);
                this.mesh.position.x = calculateX(meta.rect.x + meta.rect.width / 2);
                this.mesh.position.y = calculateY(meta.rect.y + meta.rect.height / 2);
                this.mesh.frustumCulled = false;
            }
        };

        meta.setMeshPosition();

        viewportHandler.meta.onChange.add(meta.setMeshPosition);

        return () => {
            viewportHandler.meta.onChange.delete(meta.setMeshPosition);
            context.kill(true);
        };
    }, {shouldResetOnResize: document.body});

    constructor(onload: (mesh: Object3D) => void) {
        const textures = Chair.textures;
        const material = createTextileMaterial(
            {
                map: textures.diffuse,
                roughnessMap: textures.roughness,
                roughness: 2,
                normalMap: textures.normal,
            },
            new Color(0xffffff),
            uniforms => {
                this.sharedUniforms = uniforms;
                configureLilGUI(gui => {
                    gui.addColor(uniforms.uColor, 'value').name('chair color');
                });
            }
        );

        modelLoader.load("/models/chair.glb", (gltf) => {
            const mesh = this.mesh = gltf.scenes[0].children[0] as Mesh<BufferGeometry, MeshStandardMaterial>;
            mesh.material = material;
            mesh.material.transparent = true;
            mesh.material.opacity = 0;
            mesh.position.z = Stage.objectsZ - 200;

            this.positionController.meta.setMeshPosition();
            this.animate();
            Chair.instance = this;
            if (Chair.textures.isLoaded) {
                onload(mesh);
                this.onLoaded();
            } else {
                Chair.textures.addEventListener('loaded', () => {
                    onload(mesh);
                    this.onLoaded();
                });
            }

            // force correct position
            this.positionController.reset();
            setTimeout(this.positionController.reset, 500);
        });
    }

    private scrollDynamics = new SecondOrderDynamics(2.5, 1, 1.25, 0);
    private pointerXDynamics = new SecondOrderDynamics(1, 0.75, 2, 0.5);
    private pointerYDynamics = new SecondOrderDynamics(1, 1, 1.2, 0.5);
    private getYaw = mapRange(-1, 1, -OBJECT_LOOKAT_ANGLES.x, OBJECT_LOOKAT_ANGLES.x);
    private getPitch = mapRange(-1, 1, -OBJECT_LOOKAT_ANGLES.y, OBJECT_LOOKAT_ANGLES.y);
    private normalizedToWorld = mapRange(0, 1, -1, 1);
    scrollTween?: gsap.core.Tween;

    rotateZOffset = 0;

    onLoaded() {
        gsap.to(this.mesh.material, {opacity: 1, duration: 1});
    }

    animate() {
        if (!this.mesh) {
            return;
        }

        const scrollRotation = {angle: OBJECT_SCROLL_ROTATION_START};
        let pointerX = 0.5;
        let pointerY = 0.5;
        let step = 0;
        let scrollPitchVelocity = 0;

        this.scrollTween = gsap.fromTo(scrollRotation, {
            angle: OBJECT_SCROLL_ROTATION_START,
        }, {
            angle: OBJECT_SCROLL_ROTATION_END,
            ease: 'none',
            scrollTrigger: {
                trigger: this.positionController.meta.sentinel,
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
            },
        });

        gsap.ticker.add((_, deltaTime) => {
            if (!this.mesh) {
                return;
            }

            step = deltaTime / 1000;
            scrollPitchVelocity = (ScrollSmoother.get()?.getVelocity() ?? 0) * 0.0002;

            if (Pointer.instance) {
                pointerX = this.normalizedToWorld(Pointer.instance.normalX);
                pointerY = this.normalizedToWorld(Pointer.instance.normalY);
            }

            this.mesh.rotation.z = this.scrollDynamics.update(step, scrollRotation.angle) + this.rotateZOffset;
            this.mesh.rotation.z += this.pointerXDynamics.update(step, this.getYaw(pointerX));
            this.mesh.rotation.x = OBJECT_ROTATION_X + this.pointerYDynamics.update(step, this.getPitch(pointerY) + scrollPitchVelocity);
        });
    }
}
