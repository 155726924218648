import {Color, IUniform, MeshStandardMaterial, MeshStandardMaterialParameters} from "three";

export function createTextileMaterial(
    params: MeshStandardMaterialParameters,
    color: Color,
    callback: (uniforms: Record<string, IUniform>) => void
) {
    const material = new MeshStandardMaterial({...params, wireframe:false});

    material.onBeforeCompile = (shader) => {
        shader.uniforms.uColor = {value: color};

        shader.fragmentShader = [
            'uniform vec3 uColor;',
            shader.fragmentShader,
        ].join('\n');

        shader.fragmentShader = shader.fragmentShader.replace(
            '#include <map_fragment>',
            `#include <map_fragment>
            diffuseColor *= vec4(uColor, 1.0);`
        );

        callback(shader.uniforms);
    };

    return material;
}
