import {Frame} from "@/utils/getFrames";

export class SharedImageDataLoader {
    private readonly cache: Record<string, HTMLImageElement> = {};

    private createSource(src: string, type: string): HTMLSourceElement {
        const source = document.createElement('source');
        source.type = type;
        source.srcset = src;
        return source;
    }

    private async loadImage(source: Frame): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const picture = document.createElement('picture');
            const image = new Image();
            image.onload = () => {
                resolve(image);
            };
            picture.onerror = (event, source, lineno, colno, error) => {
                reject(error);
            };
            const sourceWebp = this.createSource(source.webp, 'image/webp');
            const fallbackSources = source.fallback.map((fallback) => this.createSource(fallback.url, fallback.type));
            picture.append(sourceWebp, ...fallbackSources, image);
            // image.decoding = 'async';
            image.src = source.webp;
        });
    }

    private key(frame: Frame): string {
        return frame.webp;
    }

    async get(source: Frame): Promise<HTMLImageElement> {
        const cache = this.cache[this.key(source)];
        if (cache) {
            return cache;
        }
        const bitmap = await this.loadImage(source);
        this.cache[this.key(source)] = bitmap;
        return bitmap;
    }
}
