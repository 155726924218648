import {debounce} from 'lodash';
import {useEffect, useState} from 'react';

const useWindow = (onResize?: ({width, height}) => void, wait = 50) => {
    const [screen, setScreen] = useState<{
        width?: number,
        height?: number,
    }>({
        width: undefined,
        height: undefined,
    });

    const [supportWebP, setSupportWebP] = useState<boolean>(false);

    const getValue = () => {
        return {
            width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        };
    };

    const resize = debounce(() => {
        const value = getValue();

        onResize && onResize(value);

        setScreen(value);
    }, wait);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setScreen(getValue);

            window.addEventListener('resize', resize);

            const el = document.createElement('canvas');

            if (el.getContext && el.getContext('2d')) {
                setSupportWebP(el.toDataURL('image/webp').indexOf('data:image/webp') == 0);
            }

            return () => {
                window.removeEventListener('resize', resize);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...screen,
        supportWebP,
    };
};

export default useWindow;
